import { Button, TextField, Theme, Typography } from "@suraasa/placebo-ui"
import api from "api"
import React from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { Platforms } from "utils/constants"
import { handleErrors } from "utils/helpers"
import toast from "utils/toast"
import AuthLayout from "views/auth/Layout"

const useStyles = createUseStyles((theme: Theme) => ({
  title: {
    letterSpacing: "-0.04em",
    marginBottom: theme.spacing(1),
  },
}))

type FormData = {
  newPassword: string
  confirmPassword: string
}

type Props = {
  redirectUrl: string
  platform: Platforms
}
const ResetPassword = ({ redirectUrl, platform }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const classes = useStyles()

  const { uuid, token, timestamp } =
    useParams<{ uuid: string; token: string; timestamp: string }>()

  const newPassword = watch("newPassword")

  const onSubmit = handleSubmit(async data => {
    if (!uuid || !token || !timestamp) {
      return
    }
    const res = await api.users.resetPassword({
      data,
      urlParams: {
        uuid,
        token,
        timestamp,
      },
    })
    if (res.isSuccessful) {
      toast.success("Your password has been changed successfully")
      window.location.href = redirectUrl
    } else {
      handleErrors(setError, res.errors)
    }
  })

  return (
    <AuthLayout platform={platform}>
      <form onSubmit={onSubmit}>
        <Typography className={classes.title} variant="title1">
          Set a New Password
        </Typography>
        <Typography className="mb-4">
          Set a secure password. Make sure it includes alphabets as well as
          numbers. It should be at least 8 characters long.
        </Typography>
        <TextField
          className="mb-2"
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword?.message}
          label="New Password"
          type="password"
          autoFocus
          fullWidth
          {...register("newPassword", {
            minLength: {
              value: 8,
              message: "Password must have at least 8 characters",
            },
            onChange: () => clearErrors("confirmPassword"),
            required: { value: true, message: "Required" },
          })}
        />
        <TextField
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
          label="Confirm Password"
          type="password"
          autoFocus
          fullWidth
          {...register("confirmPassword", {
            validate: value =>
              value === newPassword || "The passwords do not match",
            required: { value: true, message: "Required" },
          })}
        />
        <Button className="mt-3" loading={isSubmitting} type="submit" fullWidth>
          Reset Password
        </Button>
      </form>
    </AuthLayout>
  )
}

export default ResetPassword
