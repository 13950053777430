import api from "api"
import { AuthData } from "api/resources/users/types"
import { useEffect } from "react"
import { clearAuthInfo, getAuthInfo, savePlatformAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"

const isSuraasaURL = (url: URL) => {
  const [TLD, domain] = url.hostname.split(".").reverse()
  return domain === "suraasa" && TLD === "com"
}

const checkOrigin = (origin: string) => {
  const url = new URL(origin)
  if (import.meta.env.VITE_ALLOW_LOCALHOST_SSO_MESSAGING === "true") {
    return isSuraasaURL(url) || url.hostname === "localhost"
  }

  return isSuraasaURL(url)
}

const OlympiadRegistrationFormSSO = () => {
  useEffect(() => {
    const listener = async (e: MessageEvent) => {
      console.log("Event rcvd", e.data)
      if (checkOrigin(e.origin)) {
        if (getAuthInfo(Platforms.student)) {
          await api.users.logout()
          clearAuthInfo(Platforms.student)
        }
        savePlatformAuthInfo(Platforms.student, e.data as AuthData)
      }
    }
    window.addEventListener("message", listener)

    return () => {
      window.removeEventListener("message", listener)
    }
  }, [])

  return <p>Olympiad Registration SSO</p>
}

export default OlympiadRegistrationFormSSO
