import {
  Button,
  Divider,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import AuthLayout from "components/auth/school/AuthLayout"
import BackButton from "components/shared/BackButton"
import ReactHelmet from "components/shared/ReactHelmet"
import metadata from "metadata.json"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getAuthInfo, isValidPlatform, savePlatformAuthInfo } from "utils/auth"
import { Platforms, SCHOOL_PLATFORM_URL } from "utils/constants"
import countryCodes from "utils/countryCodes"
import { handleErrors } from "utils/helpers"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"
import { routes } from "utils/routes"
import toast from "utils/toast"
import isURL from "validator/es/lib/isURL"

const PLATFORM = "School"

const useStyles = createUseStyles({
  form: {
    width: 358,
    maxWidth: "100%",
    "@media only screen and (max-width: 400px)": {
      width: "100%",
    },
  },
  countryCode: {
    maxWidth: "120px",
  },
  phoneNumberField: {
    maxWidth: "300px",
  },
})

type FormData = {
  // School name
  name: string
  website: string

  firstName: string
  lastName: string | null
  email: string
  countryCode: string
  phoneNumber: number
  password1: string
}

const SignUp = () => {
  const authInfo = getAuthInfo(Platforms.school)
  const navigate = useNavigate()

  if (authInfo && isValidPlatform(authInfo?.platform, [Platforms.school])) {
    window.open(SCHOOL_PLATFORM_URL, "_self")
  }

  const {
    register,
    handleSubmit,
    clearErrors,
    control,
    trigger,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({})

  const classes = useStyles()
  const [step, setStep] = useState<1 | 2>(1)

  const [searchParams] = useSearchParams()

  const { submitWithCaptcha, triggerCheckboxCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#recaptcha-checkbox-container",
  })

  const onSubmit = handleSubmit(async formData => {
    clearErrors()

    await submitWithCaptcha(async captcha => {
      const res = await api.school.signUp({
        data: {
          user: {
            captcha,
            firstName: formData.firstName,
            lastName: formData.lastName || null,
            email: formData.email,
            password1: formData.password1,
            password2: formData.password1,
            phoneNumber: `${formData.countryCode}${formData.phoneNumber}`,
            token_type: 1,
            platform: PLATFORM,
          },
          school: {
            website: formData.website,
            name: formData.name,
          },
          /**
           * This is because password1 gets converted to password_1 by lodash. We don't want that.
           * So we skip transformation.
           */
          __noTransform: true,
        },
      })
      if (res.isSuccessful) {
        savePlatformAuthInfo(Platforms.school, res.data)
        const next = searchParams.get("next")
        if (next) {
          navigate(
            `${routes.school.login}/?redirect-url=${encodeURIComponent(
              `${SCHOOL_PLATFORM_URL}/?next=${encodeURIComponent(next)}`
            )}`
          )

          return
        }
        navigate(`${routes.school.login}/?redirect-url=${SCHOOL_PLATFORM_URL}`)
      } else {
        if (res.errors.fieldErrors?.invalidCaptcha) {
          triggerCheckboxCaptcha()
          toast.error("Verification failed, please try again later")
        }
        handleErrors(setError, {
          ...res.errors,
          fieldErrors: {
            email: res.errors.fieldErrors?.email ?? "",
            firstName: res.errors.fieldErrors?.firstName ?? "",
            password1: res.errors.fieldErrors?.password1 ?? "",
            phoneNumber: res.errors.fieldErrors?.phoneNumber ?? "",
          },
        })
      }
    })()
  })

  const handleStep1Submit = async () => {
    if (await trigger(["name", "website"])) setStep(2)
  }

  return (
    <>
      <ReactHelmet data={metadata.school.signup} />
      <AuthLayout>
        <>
          {step === 1 && (
            <>
              <Typography component="h1" variant="title2">
                Create An Account.
              </Typography>
              <Typography
                className="mb-4"
                color="onSurface.500"
                variant="subtitle2"
              >
                Hire Skilled Teachers for Free!
              </Typography>
            </>
          )}
        </>
        <form
          className={clsx(classes.form, "flex flex-col gap-2")}
          onSubmit={onSubmit}
        >
          {step === 1 ? (
            <>
              <div className="flex flex-col items-center gap-2">
                <TextField
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  label="School Name"
                  placeholder="Enter School Name Here"
                  autoFocus
                  fullWidth
                  {...register("name", {
                    required: { value: true, message: "Required" },
                  })}
                />
                <TextField
                  error={Boolean(errors.website)}
                  helperText={
                    errors.website?.message || "Example: https://school.com"
                  }
                  label="School Website"
                  placeholder="Enter School Website Here"
                  type="text"
                  fullWidth
                  {...register("website", {
                    required: { value: true, message: "Required" },
                    validate: value =>
                      isURL(value, {
                        protocols: ["https", "http"],
                      }) || "Invalid URL",
                  })}
                />
              </div>
              <Button className="mt-4.5" fullWidth onClick={handleStep1Submit}>
                Continue
              </Button>
            </>
          ) : (
            <>
              <BackButton className="-ml-1.5" onBack={() => setStep(1)} />

              <div className="flex flex-col gap-2 sm:flex-row">
                <TextField
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName?.message}
                  label="First Name"
                  placeholder="John"
                  type="text"
                  autoFocus
                  fullWidth
                  {...register("firstName", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <TextField
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName?.message}
                  label="Last Name"
                  placeholder="Doe"
                  type="text"
                  fullWidth
                  {...register("lastName")}
                />
              </div>
              <div className="flex flex-col gap-2 sm:flex-row">
                <Controller
                  control={control}
                  name="countryCode"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      className={classes.countryCode}
                      error={Boolean(errors.countryCode)}
                      getOptionLabel={({ dialCode }) => dialCode}
                      // formatOptionLabel={option => (
                      //   <div className="flex gap-0.5">
                      //     <img
                      //       alt=""
                      //       src={`/assets/flags/${option.code}.svg`}
                      //     />
                      //     <Typography variant="smallBody">
                      //       ({option.dialCode})
                      //     </Typography>
                      //   </div>
                      // )}
                      getOptionValue={({ dialCode }) => dialCode}
                      helperText={errors.countryCode?.message}
                      label="Code"
                      options={countryCodes}
                      placeholder="+91"
                      value={
                        value
                          ? countryCodes.find(item => item.dialCode === value)
                          : null
                      }
                      fullWidth
                      required
                      onBlur={onBlur}
                      onChange={val => {
                        onChange(val?.dialCode)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />

                <TextField
                  className={classes.phoneNumberField}
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber?.message}
                  label="Phone Number"
                  placeholder="9845XX XXXXX"
                  type="tel"
                  fullWidth
                  {...register("phoneNumber", {
                    required: { value: true, message: "Required" },
                    maxLength: {
                      value: 12,
                      message: "Length cannot be greater than 12",
                    },
                    minLength: {
                      value: 8,
                      message: "Length cannot be less than 8",
                    },
                  })}
                />
              </div>
              <Divider />
              <Typography color="onSurface.500" variant="smallBody">
                This email and password will be used to sign in to your Suraasa
                Account.
              </Typography>
              <TextField
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                label="email address"
                placeholder="johndoe@example.com"
                type="email"
                fullWidth
                {...register("email", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                autoComplete="password"
                error={Boolean(errors.password1)}
                helperText={errors.password1?.message}
                label="password"
                type="password"
                fullWidth
                {...register("password1", {
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  required: { value: true, message: "Required" },
                })}
              />

              <div className="mt-2" id="recaptcha-checkbox-container" />
              <Button
                className="mt-2"
                loading={isSubmitting}
                type="submit"
                fullWidth
              >
                Create Account
              </Button>
              <div className="flex flex-wrap items-center justify-center">
                <Typography textAlign="center" variant="smallBody">
                  Creating an account means you agree to our
                </Typography>

                <Button
                  component="a"
                  href="https://suraasa.com/terms-of-use"
                  target="_blank"
                  type="button"
                  variant="link"
                >
                  Terms of Use
                </Button>
                <Typography textAlign="center" variant="smallBody">
                  and
                </Typography>
                <Button
                  component="a"
                  href="https://suraasa.com/privacy-policy"
                  target="_blank"
                  type="button"
                  variant="link"
                >
                  Privacy Policy.
                </Button>
              </div>
            </>
          )}
        </form>
      </AuthLayout>
    </>
  )
}

export default SignUp
