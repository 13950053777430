import {
  Button,
  Container,
  Divider,
  IconButton,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { HelpCategory } from "api/resources/help/types"
import clsx from "clsx"
import FileInput from "components/shared/FileInput"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import { InfoCircle, Plus, WarningTriangle, Xmark } from "iconoir-react"
import snakeCase from "lodash/snakeCase"
import metadata from "metadata.json"
import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { getAuthInfo } from "utils/auth"
import { acceptedImageTypes } from "utils/constants"
import { getPlatformURL, handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"
import toast from "utils/toast"

type FormData = {
  fullName?: string
  email?: string
  description: string
  category?: HelpCategory | null
  subCategory?: number
  subject?: string
}

const useStyles = createUseStyles(theme => ({
  container: {
    background: "white",
    minHeight: "93vh",
  },

  imageStatus: { background: theme.colors.secondary[50], position: "relative" },
  imageError: {
    background: theme.colors.critical[50],
  },
  imageErrorIcon: {
    position: "absolute",
    left: "-36px",
    color: theme.colors.critical[700],
    width: "20px",
    height: "20px",
  },
  removeImageButton: {
    width: "17px",
    height: "17px",
    marginLeft: "auto",
    "& > svg": { width: "13px", height: "13px" },
  },
}))

const validateImageUpload = (file: File) => {
  let errorType: null | string = null

  if (!file.type.includes("image")) {
    errorType = "Only images are allowed"
  }
  if (!errorType && file.size / 1024 / 1024 > 5) {
    errorType = "Only images under 5 MB are allowed"
  }

  if (!errorType && !acceptedImageTypes.includes(file.type)) {
    errorType =
      "Invalid Image Type. Only jpg, jpeg, png & webp formats are allowed"
  }

  return {
    image: file,
    errorMessage: errorType,
    id: `${file.name}${Date.now()}`,
  }
}

function Help() {
  const classes = useStyles()

  const auth = getAuthInfo()

  const [loading, setLoading] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const uploadedImages = useArray<{
    image: File
    errorMessage: string | null
    id: string
  }>([])

  const [categoryOptions, setCategoryOptions] = useState<HelpCategory[]>([
    {
      id: 0,
      name: "I need help with something else",
      subCategories: [],
    },
  ])

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: auth?.user.email ?? "",
    },
  })

  const category = watch("category")
  const subCategory = watch("subCategory")

  const subCategoryOptions = category?.subCategories.length
    ? [...category.subCategories, { id: 0, name: "Others", parent: 0 }]
    : []

  useEffect(() => {
    const getHelpCategories = async () => {
      setLoading(true)
      const res = await api.help.list()
      if (res.isSuccessful) {
        setCategoryOptions([
          ...res.data,
          {
            id: 0,
            name: "I need help with something else",
            subCategories: [],
          },
        ])
      } else if (res.errors.message) toast.error(res.errors.message)
      setLoading(false)
    }

    getHelpCategories()
  }, [])

  const uploadImages = (fileArray: File[]) => {
    const remainingUploadLimit = 5 - uploadedImages.array.length
    if (fileArray.length > remainingUploadLimit) {
      toast.error("Upto 5 images allowed")
    }

    // Check if all the images uploaded pass validation check
    for (let i = 0; i < fileArray.length && i < remainingUploadLimit; i += 1) {
      uploadedImages.push(validateImageUpload(fileArray[i]))
    }
  }

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    if (uploadedImages.array.find(item => item.errorMessage !== null)) {
      toast.error("Remove invalid images and try again")
      setLoading(false)
      return
    }

    const formData = {
      ...data,
      platform: 1,
      subCategory: data.subCategory || "",
      category: data.category?.id || "",
      subject:
        data.category?.id === 0 ||
        data.subCategory === 0 ||
        subCategoryOptions.length === 0
          ? data.subject
          : "",
    }
    const files = new FormData()

    if (uploadedImages.array.length) {
      uploadedImages.array.forEach(item =>
        files.append("attachments", item.image)
      )
    }
    Object.entries(formData).forEach(item =>
      files.append(String(snakeCase(item[0])), String(item[1]))
    )

    const res = await api.help.create({
      data: files,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    if (res.isSuccessful) {
      reset({ category: null, email: auth?.user.email ?? "" })
      toast.success("Submitted successfully. We will get back to you soon")
      uploadedImages.clear()
    } else if (res.errors.fieldErrors?.attachments) {
      for (const [k, v] of Object.entries(
        res.errors.fieldErrors?.attachments
      )) {
        const idx = Number(k)
        uploadedImages.update(idx, {
          ...uploadedImages.array[idx],
          errorMessage: v[0],
        })
      }
    } else handleErrors(setError, res.errors)

    setLoading(false)
  })

  return (
    <>
      <ReactHelmet data={metadata.help} />
      <Navbar
        gutterBottom={false}
        slotEnd={
          auth ? undefined : (
            <a
              href={getPlatformURL("learn", "/")}
              style={{ textDecoration: "none" }}
            >
              <Typography variant="button">Go to Homepage</Typography>
            </a>
          )
        }
        slotStart={<Typography variant="title2">Help</Typography>}
      />
      <div className={clsx(classes.container, "pt-3")}>
        <Container>
          <div className="grid grid-cols-12 justify-center gap-2.5 md:gap-3">
            <div className="col-start-1 col-end-12 sm:col-start-3 sm:col-end-11">
              <Typography component="h1" variant="title3">
                Help using Suraasa
              </Typography>
              <form className="mt-5 flex flex-col gap-3" onSubmit={onSubmit}>
                <FileInput
                  accept={acceptedImageTypes.toString()}
                  limit={20}
                  name="gallery-images"
                  ref={fileInputRef}
                  onChange={files => uploadImages(files)}
                />
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      error={Boolean(errors.category)}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id.toString()}
                      isLoading={loading}
                      label="Please tell us more"
                      options={categoryOptions}
                      placeholder="Select one"
                      value={value}
                      fullWidth
                      required
                      onChange={newValue => {
                        onChange(newValue)
                        setValue("subCategory", undefined)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />

                {/* Hide the rest of the form until a category is selected */}
                {category && (
                  <>
                    {!auth && (
                      <TextField
                        error={Boolean(errors.fullName)}
                        helperText={errors.fullName?.message}
                        label="Your full name"
                        placeholder="Enter your full name"
                        fullWidth
                        {...register("fullName", {
                          required: { value: true, message: "Required" },
                        })}
                      />
                    )}

                    <TextField
                      disabled={Boolean(auth)}
                      error={Boolean(errors.email)}
                      helperText={
                        errors.email?.message ||
                        "We’ll use this email address to contact you."
                      }
                      label="Your email"
                      placeholder="Enter your official e-mail address"
                      fullWidth
                      {...register("email", {
                        required: { value: true, message: "Required" },
                      })}
                      type="email"
                    />
                    <Divider className="my-6" />

                    {subCategoryOptions.length > 0 && (
                      <Controller
                        control={control}
                        name="subCategory"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            error={Boolean(errors.subCategory)}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id.toString()}
                            helperText={
                              errors.subCategory?.message ||
                              "Select others if you can’t find your issue"
                            }
                            key={value}
                            label="What issue you are experiencing?"
                            options={subCategoryOptions}
                            placeholder="Select one"
                            value={subCategoryOptions.find(
                              item => item.id === value
                            )}
                            fullWidth
                            required
                            onChange={newValue => onChange(newValue?.id)}
                          />
                        )}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                        shouldUnregister
                      />
                    )}

                    {(subCategory === 0 || subCategoryOptions.length === 0) && (
                      <TextField
                        error={Boolean(errors.subject)}
                        helperText={errors.subject?.message}
                        label="Subject"
                        placeholder="Enter subject"
                        fullWidth
                        {...register("subject", {
                          required: { value: true, message: "Required" },
                        })}
                      />
                    )}

                    {/* @ts-expect-error placebo-issue */}
                    <TextField
                      className="mb-1.5"
                      error={Boolean(errors.description)}
                      helperText={
                        errors.description?.message ||
                        "Please include as many details as possible about what’s happening"
                      }
                      label="Describe the problem you’re having"
                      rows={4}
                      fullWidth
                      multiLine
                      {...register("description", {
                        required: { value: true, message: "Required" },
                      })}
                    />
                    <Divider className="my-6" />
                    <div>
                      <div className="flex items-center gap-0.5">
                        <Typography variant="preTitle">UPLOAD </Typography>

                        <Tooltip
                          maxWidth="281px"
                          title="Upload upto 5 images of max 5 MB each. We accept PNG, 
                          JPG, GIF, and WEBP formats."
                        >
                          <InfoCircle
                            style={{ width: "17px", height: "17px" }}
                          />
                        </Tooltip>
                      </div>
                      <Typography variant="smallBody">
                        Share screenshots or other images to help us understand
                        the issue you’re having on Suraasa.
                      </Typography>
                    </div>

                    {uploadedImages.array.length > 0 && (
                      <div className="flex flex-col gap-0.5">
                        {uploadedImages.array
                          .slice(0)
                          .reverse()
                          .map(item => (
                            <div
                              className={clsx(
                                classes.imageStatus,
                                "flex items-center p-0.75",
                                {
                                  [classes.imageError]: Boolean(
                                    item.errorMessage
                                  ),
                                }
                              )}
                              key={item.id}
                            >
                              {item.errorMessage && (
                                <Tooltip title={item.errorMessage}>
                                  <WarningTriangle
                                    className={classes.imageErrorIcon}
                                  />
                                </Tooltip>
                              )}

                              <Typography>{item.image.name}</Typography>
                              <IconButton
                                className={classes.removeImageButton}
                                color="secondary"
                                onClick={() =>
                                  uploadedImages.removeByKey(item.id)
                                }
                              >
                                <Xmark />
                              </IconButton>
                            </div>
                          ))}
                      </div>
                    )}

                    <Button
                      disabled={uploadedImages.array.length > 4}
                      size="sm"
                      startAdornment={<Plus />}
                      variant="outlined"
                      onClick={() => fileInputRef.current?.click()}
                    >
                      Upload Images
                    </Button>

                    <Button
                      className="my-3"
                      loading={loading}
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </form>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Help
