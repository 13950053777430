import { routes } from "utils/routes"
import SignUp from "views/auth/SignUp"

import Layout from "../Layout"
import { useRyanSignupTracking } from "./useRyanSignupTracking"

type Props = any

const RyanSignup = (props: Props) => {
  useRyanSignupTracking()

  return (
    <Layout>
      <SignUp hideNavBar signInRoute={routes.ryanSchool.signin} />
    </Layout>
  )
}

export default RyanSignup
