import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import ReactHelmet from "components/shared/ReactHelmet"
import metadata from "metadata.json"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getAuthRoute, getCurrentPlatform } from "utils/auth"
import { Product } from "utils/constants"
import { Captcha, useGRecaptcha } from "utils/hooks/useGRecaptcha"
import toast from "utils/toast"

import AuthLayout from "./Layout"

type FormData = {
  identifier: string
}

type Props = {
  product: Product
}

const ForgotPassword = ({ product }: Props) => {
  const [searchParams] = useSearchParams()

  const { platform } = getCurrentPlatform()

  const prefillData =
    searchParams.get("email") ||
    searchParams.get("phoneNumber") ||
    searchParams.get("identifier")

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      identifier: prefillData || "",
    },
  })

  const navigate = useNavigate()

  const getUserDetails = async (identifer: string) => {
    const res = await api.users.getUserDetails({
      data: {
        userId: identifer,
        product,
      },
    })
    if (res.isSuccessful) {
      return res.data
    }
    if (res.errors.message) {
      toast.error(res.errors.message)
    }
    if (res.errors.fieldErrors?.userId) {
      setError("identifier", { message: res.errors.fieldErrors.userId })
    }
    if (res.errors.fieldErrors?.countryCode) {
      setError("identifier", { message: res.errors.fieldErrors.countryCode })
    }
  }

  const { submitWithCaptcha, triggerCheckboxCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#recaptcha-checkbox-container",
  })

  const forgotPassword = async (identifer: string, captcha: Captcha) => {
    if (!captcha) {
      toast.error("Please verify the captcha")
      return
    }

    const data = await getUserDetails(identifer)
    if (data) {
      const res = await api.users.forgotPassword({
        data: {
          captcha,
          uuid: data?.uuid,
          product,
          /**
           * TODO: Send platform here after discussion with Chirag & Sukhvinder
           * Because, one product can correspond to multiple platforms.
           * So, after resetting, in order to redirect the user back to their original portal, I need the platform.
           * Right now I will just redirect to the most probable platform that the user might have come from.
           * Check ResetPassword file for this code.
           */
        },
      })
      if (res.isSuccessful) {
        navigate(
          getAuthRoute({
            key: "login",
            product,
          }),
          {
            replace: true,
            state: {
              bannerText: `Instructions to reset your password has been sent to you on ${
                data?.email ?? "your email"
              }.`,
            },
          }
        )
      } else if (res.errors.fieldErrors?.invalidCaptcha) {
        toast.error("Verification failed, please try again later")
        triggerCheckboxCaptcha()
      }
    }
  }

  const onSubmit = handleSubmit(async formData => {
    await submitWithCaptcha(captcha =>
      forgotPassword(formData.identifier, captcha)
    )()
  })

  return (
    <AuthLayout platform={platform}>
      <ReactHelmet data={metadata.forgotPassword} />
      <form className="flex flex-col" onSubmit={onSubmit}>
        <Typography className="mb-3" component="h1" variant="title1">
          Forgot Password?
        </Typography>
        <Typography className="mb-3" color="onSurface.800">
          Enter the email address or phone number you used when you joined and
          we’ll send you instructions to reset your password to the email linked
          to your account.
        </Typography>
        <TextField
          error={Boolean(errors.identifier)}
          helperText={errors.identifier?.message}
          label="Email or Phone Number"
          autoFocus
          fullWidth
          {...register("identifier", {
            required: { value: true, message: "Required" },
          })}
        />

        <div className="mt-2" id="recaptcha-checkbox-container" />

        <Button className="mt-2" loading={isSubmitting} type="submit" fullWidth>
          Send Reset Instructions
        </Button>
      </form>
    </AuthLayout>
  )
}

export default ForgotPassword
