import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import AuthLayout from "components/auth/school/AuthLayout"
import BackButton from "components/shared/BackButton"
import ReactHelmet from "components/shared/ReactHelmet"
import metadata from "metadata.json"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { Product } from "utils/constants"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"
import toast from "utils/toast"

const useStyles = createUseStyles({
  root: {
    width: 358,
    maxWidth: "100%",
    "@media only screen and (max-width: 400px)": {
      width: "100%",
    },
  },
})

type FormData = {
  email: string
}

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const classes = useStyles()
  const [emailSent, setEmailSent] = useState(false)

  const { submitWithCaptcha, triggerCheckboxCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#recaptcha-checkbox-container",
  })

  const onSubmit = handleSubmit(async data => {
    await submitWithCaptcha(async captcha => {
      const res = await api.users.forgotPassword({
        data: { ...data, product: Product.school, captcha },
      })
      if (res.isSuccessful) {
        toast.success("Reset password e-mail has been sent")
        setEmailSent(true)
      } else {
        setEmailSent(false)

        if (res.errors.fieldErrors?.invalidCaptcha) {
          triggerCheckboxCaptcha()
          toast.error("Verification failed, please try again later")
        }
        if (res.errors.fieldErrors?.user) {
          setError("email", { message: res.errors.fieldErrors?.user })
        }
      }
    })()
  })

  const resetForm = () => {
    setEmailSent(false)
    reset({})
  }

  return (
    <>
      <ReactHelmet data={metadata.forgotPassword} />
      <AuthLayout>
        <BackButton className="-ml-1.5 mb-3" />
        <div className={classes.root}>
          {emailSent ? (
            <>
              <Typography className="mb-2" variant="title2">
                Reset password e-mail has been sent to {getValues("email")}
              </Typography>
              <div className="mt-4 flex flex-col gap-2 sm:flex-row">
                <Button fullWidth onClick={onSubmit}>
                  Resend
                </Button>
                <Button variant="text" fullWidth onClick={resetForm}>
                  Change Email
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography className="mb-2" component="h1" variant="title2">
                Forgot your password?
              </Typography>
              <Typography
                className="mb-4"
                color="onSurface.500"
                variant="subtitle2"
              >
                Please enter the email address associated with your Suraasa
                account.
              </Typography>
              <form
                className={clsx("mt-2 flex flex-col", classes.root)}
                onSubmit={onSubmit}
              >
                <TextField
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  label="email address"
                  type="email"
                  autoFocus
                  fullWidth
                  {...register("email", {
                    required: { value: true, message: "Required" },
                  })}
                />

                <div className="mt-2" id="recaptcha-checkbox-container" />

                <Button
                  className="mt-2"
                  loading={isSubmitting}
                  type="submit"
                  fullWidth
                >
                  Send Verification
                </Button>
              </form>
            </>
          )}
        </div>
      </AuthLayout>
    </>
  )
}

export default ForgotPassword
