import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { ryanGroupIdentifier } from "utils/constants"

export const useRyanSignupTracking = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    searchParams.set("signup_source", ryanGroupIdentifier)

    if (!searchParams.get("utm_source")) {
      searchParams.set("utm_source", ryanGroupIdentifier)
    }

    setSearchParams(searchParams, { replace: true })
  }, [])
}
