import { Typography } from "@suraasa/placebo-ui"
import BeanVector from "assets/illustrations/beanVector.svg"
import Logo from "assets/logos/suraasa-x-ryan.svg"
import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    height: "100vh",
    background: theme.colors.common.white[500],
    [theme.breakpoints.up("sm")]: {
      background: theme.colors.surface[50],
    },
  },
  branding: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    background: "linear-gradient(180deg, #1F3674 0%, #4666F6 121.44%), #1F3674",

    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },

    "& > div": {
      padding: theme.spacing(3, 2),
      [theme.breakpoints.only("sm")]: {
        padding: theme.spacing(5, 5, 7, 5),
      },

      [theme.breakpoints.up("md")]: {
        maxWidth: 356,
        margin: "0px auto",
        padding: theme.spacing(16, 0, 0, 0),
      },
    },

    "& .bean1": {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
        position: "absolute",
        bottom: 123,
        left: -74,
      },
    },
    "& .bean2": {
      position: "absolute",
      width: 226,
      height: 114,
      bottom: 49,
      right: -40,

      [theme.breakpoints.up("md")]: {
        bottom: 72,
      },
    },
  },
  formContainer: {
    width: 500,
    maxWidth: "100%",
    margin: "auto",
    padding: theme.spacing(4, 2),
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      padding: theme.spacing(16, 0.5, 8, 0.5),
    },
  },
}))

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={clsx("md:grid md:grid-cols-5", classes.root)}>
      <div
        className={clsx("col-span-5 md:col-span-2 md:flex", classes.branding)}
      >
        <div>
          <img src={Logo} alt="" />
          <Typography
            className="mb-0.5 mt-2"
            color="onSurface.50"
            variant="title1"
          >
            Create your Suraasa Account.
          </Typography>
          <Typography color="onSurface.300" variant="title3">
            Ryan Group of Schools
          </Typography>
          <img src={BeanVector} alt="" className="bean1" />
          <img src={BeanVector} alt="" className="bean2" />
        </div>
      </div>
      <div
        className={clsx(
          classes.formContainer,
          "col-span-5 flex flex-col items-center md:col-span-3"
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Layout
