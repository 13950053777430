import React from "react"
import { routes } from "utils/routes"
import HybridLogin from "views/auth/HybridLogin"

import Layout from "../Layout"
import { useRyanSignupTracking } from "./useRyanSignupTracking"

type Props = any

const RyanSignIn = (props: Props) => {
  useRyanSignupTracking()

  return (
    <Layout>
      <HybridLogin hideNavBar signUpRoute={routes.ryanSchool.signup} />
    </Layout>
  )
}

export default RyanSignIn
