import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const isSuraasaURL = (url: URL) => {
  const [TLD, domain] = url.hostname.split(".").reverse()
  return domain === "suraasa" && TLD === "com"
}

const isLocalhostURL = (url: URL) => {
  const [TLD] = url.hostname.split(".").reverse()
  return TLD === "localhost"
}

const isSecure = (url: URL) => url.protocol === "https:"

const ZoomAuth = () => {
  const [searchParams] = useSearchParams()
  const [error, setError] = useState("")

  useEffect(() => {
    const redirectUrl = searchParams.get("redirect-url")

    if (redirectUrl) {
      try {
        const newUrl = new URL(redirectUrl)

        const isValid =
          import.meta.env.VITE_ALLOW_LOCALHOST_REDIRECT === "true"
            ? isLocalhostURL(newUrl) ||
              (isSecure(newUrl) && isSuraasaURL(newUrl))
            : isSecure(newUrl) && isSuraasaURL(newUrl)

        if (!isValid) {
          setError("Invalid URL. Please check the URL and try again")
          return
        }
        searchParams.delete("redirect-url")
        searchParams.forEach((value, key) => {
          newUrl.searchParams.append(key, value)
        })

        window.location.replace(newUrl.toString())
        return
      } catch (e) {
        setError("Invalid URL. Please check the URL and try again")
      }
    } else {
      setError("Invalid URL. Please check the URL and try again")
    }
  }, [searchParams])

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: 8,
      }}
    >
      {error ? (
        <Typography variant="title2">{error}</Typography>
      ) : (
        <>
          <CircularProgress />
          <Typography variant="title2">You are being redirected</Typography>
        </>
      )}
    </div>
  )
}
export default ZoomAuth
